import React from 'react';
import '../style/app.scss';
import SEO from '../components/seo';
import styled from 'styled-components';
import jessica from '../images/jessica-main.jpg';
import Background from '../components/Background';
import Arrow from '../components/Arrow';
import MobileImageJessica from '../components/Images/Jessica'
import { Link } from 'gatsby'
import Brandmark from '../components/Brandmark';
import Jumbotron from '../components/Jumbotron';
import Microdata from '../components/Microdata';

const Container = styled.div`
    position: relative;
    display: flex;
    align-items: center;
    flex-direction: column;
`;

const PageBackground = styled.div`
	position: absolute;
	height: 900px;
	left: 0;
	bottom: 0;
	right: 0;
	overflow: hidden;
	
	& > svg {
		z-index: 1;
        position: absolute;
	    color: #404056;
	    opacity: 0.2;
	    top: -50%;
	    left: -50%;
	    width: 200%;
	    height: 200%;
	}
`;

const ProfileWrapper = styled.div`
    display: flex;
    z-index: 2;
	width: 100%;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	
	@media (min-width: 1125px) {
        flex-direction: row;
        min-height: 550px;
        align-items: initial;
        margin-bottom: 10rem;
	}
`;

const Title = styled.h1`
    position: relative;
	font-size: 24px;
	color: #000;
	margin-bottom: 4rem;
	margin-top: 4rem;
	text-align: center;
	max-width: 92%;
	
	@media (min-width: 1125px) {
        font-size: 32px;
	}
	
	svg {
	    position: absolute;
	    width: 175px;
	    left: 0;
	    right: 0;
	    top: 0;
	    bottom: 0;
	    margin: auto;
	    opacity: 0.2;
	}
`;

const ProfileContainer = styled.div`
	display: flex;
	justify-content: center;
    flex-direction: column;
    max-width: 350px;
    width: 95%;
	
	@media (min-width: 1125px) {
        flex-direction: row;
        width: ${325 * 1}px;
        max-width: initial;
        box-shadow:  0 15px 30px 0 rgba(0, 0, 0, 0.25);
	}
`;

const Mask = styled.div`
    display: none;
	
	@media (min-width: 1125px) {
	    display: initial;
        position: absolute;
        bottom: 0;
        top: 50%;
        left: 0;
        right: 0;
        background: linear-gradient(to top, black, transparent);
	}
`;

const Info = styled.div`
    padding: 2rem;
    
	h2 {
	    color: #000;
	    font-size: 24px;
	}
	
	@media (min-width: 1125px) {
        position: absolute;
        color: #fff;
        z-index: 2;
        top: 27.5rem;
        transition: top 220ms cubic-bezier(0.15, 1, 0.3, 1);
        height: 550px;
        width: 100%;
        
        h2 {
            color: #fff;
        }
	}
`;

const Profile = styled.div`
    background-color: #fff;
    box-shadow: 0 0 15px 0 rgba(0,0,0,0.05);
    margin-bottom: 2rem;
	
	@media (min-width: 1125px) {
	    position: relative;
        display: flex;
        align-items: flex-end;
        flex: 0 0 325px;
        transition: all 220ms ease;
        z-index: 1;
        overflow: hidden;
        background: url(${props => props.image});
        background-color: #000;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
	    box-shadow: none;
	    margin-bottom: 0;
	    
	    &::after {
            content: '';
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #000;
            transition: opacity 220ms ease;
        }
        
        &:hover {
            transform: scale(1.2);
            z-index: 2;
            box-shadow: 0 0 30px 0 rgba(0, 0, 0, 0.25);
            
            &:after {
                opacity: 0.75;
            }
            
            ${Info} {
                top: 0;
            }
        }
	}
`;

const InfoHeader = styled.div`
	margin-bottom: 2rem;
	top: 2rem;
	height: 66px;
	
	@media (min-width: 1125px) {
        position: absolute;
	}
`;

const Button = styled.button`
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #3F3F55;
    border-radius: 8px;
	outline: none;
	color: #fff;
	border: 1px solid #fff;
	padding: 1rem;
	width: 100%;
	font-size: 16px;
	margin-top: 1rem;
	transition: all 220ms ease;
	
	@media (min-width: 1125px) {
        background: transparent;
        border-radius: 0;
	}
	
	& + & {
	    margin-bottom: 0;
	}
	
	svg {
	    width: 20px;
	    height: 20px;
	    margin-left: 5px;
	}
	
	&:active {
        background-color: #323248;
    }
	
    @media (min-width: 1125px) {
        &:hover, &:active {
            color: #000;
            background-color: #fff;
            border-color: transparent;
        }
    }
`;

const BioExcerpt = styled.div`
    margin-bottom: 2rem;
    color: #000;
	
	@media (min-width: 1125px) {
	    color: #fff;
        position: absolute;
        top: 6.75rem;
        left: 2rem;
        right: 2rem;
        height: 315px;
        margin-bottom: 1rem;
	}
`;

const DesktopBreak = styled.br`
	display: none;
	
	@media (min-width: 1125px) {
	    display: initial;
	}
`;

const ButtonContainer = styled.div`
	
	@media (min-width: 1125px) {
	    position: absolute;
        bottom: 2rem;
        left: 2rem;
        right: 2rem;
	}
`;

const IndexPage = () => (
    <>
        <SEO title="Compass Healing Practitioners | Leading Emotion Code, Body Code Practitioners"/>
        <Microdata />
        <Container>
            <PageBackground>
                <Background/>
            </PageBackground>
                <Jumbotron />
                <Title><Brandmark/>Meet our top-rated team of<DesktopBreak /> Emotion Code and Body Code practitioners:</Title>
                <ProfileWrapper>
                    <ProfileContainer>
                        <Profile image={jessica}>
                            <Mask />
                            <MobileImageJessica />
                            <Info>
                                <InfoHeader>
                                    <h2>Jessica Zevallos</h2>
                                    <p>CECP, CBCP</p>
                                </InfoHeader>

                                <BioExcerpt>Hi! I am Jessica. I have lived in Utah in my entire life. I have two sweet boys and am married to my best friend, Emanuel. We have built such a beautiful life together. Emanuel introduced me to the Emotion Code in 2019 and my life has never been the same since. I am a survivor of narcissistic abuse and want to help empower others...</BioExcerpt>

                                <ButtonContainer>
                                    <Link to={'jessica'}><Button>Read More <Arrow/></Button></Link>
                                    <a href="https://compasshealing.as.me/"><Button>Book A Session</Button></a>
                                </ButtonContainer>
                            </Info>
                        </Profile>
                    </ProfileContainer>
                </ProfileWrapper>
        </Container>
    </>
);

export default IndexPage;
