import React from 'react';
import styled from 'styled-components';
import EmanuelJess from '../components/Images/EmanuelJess';
import Signature from '../components/Images/Signature';
import Logo from './Logo';
import signature from '../images/signature.jpg';

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
	margin-bottom: 6rem;
	padding: 1rem 3rem;
	background: #fff;
	z-index: 2;
	width: 100%;
	max-width: 1400px;
`;

const Columns = styled.div`
	display: flex;
	align-items: center;
`;

const Text = styled.div`
    color: #000;
    
    @media (min-width: 1125px) {
        padding: 3rem;
    }
    
	h1 {
	    display: none;
	    
        @media (min-width: 1125px) {
            display: block;
            font-weight: 500;
            margin-bottom: 2rem;
            text-align: left;
            font-size: 30px;
        }
	}
	
	p {
	    font-family: Merriweather, serif;
	    line-height: 1.5;
	    margin-bottom: 1rem;
	}
`;

const ImageWrapper = styled.div`
    position: relative;
	width: 100%;
	max-width: 500px;
	margin: 0 auto;
	
	&:after {
	    content: '';
	    height: 110%;
	    width: 110%;
	    background-color: #3f3f55;
	    position: absolute;
	    top: -5%;
	    left: -5%;
	    border-radius: 500px;
	    z-index: -1;
	    box-shadow: 20px 20px 50px 6px rgba(0, 0, 0, 0.3);
	}
	
	img {
		border-radius: 500px;
	}
`;

const Column = styled.div`

	@media (min-width: 1125px) {
        flex: 0 0 50%;
	}
`;

const SigWrapper = styled.div`
	width: 200px;
	margin-bottom: 1rem;
`;

const LogoWrapper = styled.div`
	display: flex;
	align-items: center;
	flex-direction: column;
	width: 100px;
	margin: 0 auto 2rem;
	
	@media (min-width: 1125px) {
        margin: 0;
        margin-bottom: 2rem;
	}
`;

const MobileImage = styled.div`
    margin-bottom: 2rem;
    
	@media (min-width: 1125px) {
		display: none;
	}
`;

const Jumbotron = () => {
	return (
		<Container>
            <Columns>
                <Column>
                    <Text>
                        <LogoWrapper>
                            <Logo/>
                        </LogoWrapper>
                        <h1>Welcome to Compass Healing!</h1>

                        <MobileImage><EmanuelJess/></MobileImage>
                        <p>I’m Emanuel Zevallos and I have been a Certified Emotion Code and Body Code practitioner for six years. My wife Jessica Zevallos and I work together, and she is currently a Certified Emotion Code and Body Code Practitioner.</p>
                        <p>I used to work with 80 clients a week, and following my passion has been a huge blessing in my life. Our practice has the highest amount of five-star reviews in the world, and it has truly been an honor to help clients all over the globe. I recently became the Chief Healing Officer of Inside Out Institute to assist them with global expansion.</p>
                        <p>I personally selected the best practitioners, with excellent service experience and impressive testimonials, to join us in our journey. You are in very capable hands with these amazing practitioners. I’ve personally mentored and trained them for the last year so that they may assist you the same way I would, or better.</p>
                        <p>May your healing journey be blessed,</p>
                        <SigWrapper>
                            <img src={signature} alt="signature" />
                        </SigWrapper>
                        <p>Emanuel Zevallos, CECP, CBCP<br/>Founder of Compass Healing</p>
                    </Text>
                </Column>
                <Column>
                    <ImageWrapper>
                        <EmanuelJess/>
                    </ImageWrapper>
                </Column>
            </Columns>
		</Container>
	)
}

export default Jumbotron;
